<template>
  <search-select
    v-model="selectedItem" class="form-control"
    :placeholder="placeholder"
    :name="name"
    :is-disabled="disabled"
    :options="options"
    @searchchange="onChange" >
  </search-select>
</template>

<script>

export default {
  mounted() {
    this.onLoad( this.value )
  },
  props: {
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    selectedItem: {
      type: Object,
      default: () => {}
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      required: true,
      default: ''
    }
  },
  watch: {
    value( value, oldValue ) {
      this.onLoad( value )
    },
    selectedItem( item, oldItem ) {
      this.setValue( item.value )
    }
  },
  methods: {
    setValue( value ) {
      this.$emit( "input", value)
    },
    onLoad( value ) {
      if (!value) { return }

      this.$emit( "load", {} )
    },
    onChange(keyword) {
      if (!keyword) { return }

      this.$emit( "change", { keyword } )
    }
  }
}
</script>
