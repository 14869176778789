<template>
  <div class="wrapper wrapper-content">
    <div class="row">
      <div class="col-lg-12">
        <div class="tabs-container">
          <ul class="nav nav-tabs" v-if="tabs.length > 1">
            <li v-for="tab in tabs" :key="tab.id">
              <a
                class="nav-link"
                :class="{ active: activedTab === tab.id }"
                href="javascript:void(0);"
                data-toggle="tab"
                @click="onClickTab($event, tab)"
                >{{ tab.name }}</a
              >
            </li>
          </ul>

          <div class="tab-content">
            <div
              class="tab-pane"
              v-for="tab in tabs"
              :key="tab.id"
              :id="tab.id"
              :class="{ active: activedTab === tab.id }"
            >
              <div class="panel-body">
                <fieldset>
                  <template v-for="field in fields" :aria-timestamp="timestamp">
                    <template v-if="visible(tab, field, 'uid')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <input
                            v-model="data[field.key]"
                            class="form-control"
                            type="text"
                            :name="field.key"
                            :readonly="true"
                            :disabled="disable(field)"
                          />
                          <span class="form-text" v-if="field.description">{{ field.description }}</span>
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'link')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <div class="input-group">
                            <input
                              v-if="field.alias"
                              :value="fieldValue(field, data)"
                              class="form-control"
                              type="text"
                              :name="field.key"
                              :readonly="true"
                              :disabled="disable(field)"
                            />
                            <input
                              v-else
                              v-model="data[field.key]"
                              class="form-control"
                              type="text"
                              :name="field.key"
                              :readonly="true"
                              :disabled="disable(field)"
                            />
                            <span class="input-group-append">
                              <button
                                type="button"
                                class="btn"
                                :class="field.linkClass || 'btn-primary'"
                                @click="field.link(data, _self)"
                              >
                                {{ field.linkLabel || 'Link' }}
                              </button>
                            </span>
                          </div>
                          <span class="form-text" v-if="field.description">{{ field.description }}</span>
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'text')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <input
                            v-if="field.alias"
                            :value="fieldValue(field, data)"
                            class="form-control"
                            :type="field.secure ? 'password' : 'text'"
                            :name="field.key"
                            :placeholder="field.placeholder || ''"
                            :readonly="!ediable(field)"
                            :disabled="disable(field)"
                          />
                          <input
                            v-else-if="field.onInput && field.value"
                            :value="field.value(data)"
                            @input="field.onInput(data, $event)"
                            class="form-control"
                            type="text"
                            :name="field.key"
                            :disabled="disable(field)"
                          />
                          <input
                            v-else
                            v-model="data[field.key]"
                            class="form-control"
                            :type="field.secure ? 'password' : 'text'"
                            :name="field.key"
                            :placeholder="field.placeholder || ''"
                            :readonly="!ediable(field)"
                            :disabled="disable(field)"
                          />
                          <span class="form-text" v-if="field.description">{{ field.description }}</span>
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'field')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <input
                            class="form-control"
                            :type="field.secure ? 'password' : 'text'"
                            :name="field.key"
                            :value="fieldValue(field, data)"
                            :placeholder="field.placeholder || ''"
                            :readonly="!ediable(field)"
                            :disabled="disable(field)"
                          />
                          <span class="form-text" v-if="field.description">{{ field.description }}</span>
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'textarea')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <textarea
                            v-model="data[field.key]"
                            class="form-control"
                            :rows="field.rows || 5"
                            :name="field.key"
                            :placeholder="field.placeholder || ''"
                            :readonly="!ediable(field)"
                            :disabled="disable(field)"
                          />
                          <span class="form-text" v-if="field.description">{{ field.description }}</span>
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'json')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <span class="json-text">{{ data[field.key] }}</span>
                          <span class="form-text" v-if="field.description">{{ field.description }}</span>
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'checkbox')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.title || field.label }}</label>
                        <div class="col-sm-10">
                          <input-checkbox
                            v-model="data[field.key]"
                            :name="field.key"
                            :expected="field.expected"
                            :readonly="!ediable(field)"
                            :disabled="disable(field)"
                            :ripple="true"
                            >{{
                              field.alt || (data[field.key] ? field.label : field.falseLabel || field.label)
                            }}</input-checkbox
                          >
                          <span class="form-text" v-if="field.description">{{ field.description }}</span>
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'multi-checkbox')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div v-if="field.options.length > 1" class="col-sm-10">
                          <div v-for="(option, i) in field.options" :key="option.key" style="margin-bottom: 1rem">
                            <label class="col-form-label" style="font-size: 1rem">{{ option.label }}</label>
                            <input-multi-checkbox
                              v-model="data[option.key]"
                              :options="option.options"
                              :name="option.key"
                              :readonly="!ediable(field)"
                              :disabled="disable(field)"
                              :ripple="true"
                            ></input-multi-checkbox>
                            <span class="form-text" v-if="field.description">{{ field.description }}</span>
                            <div
                              class="hr-line-dashed"
                              v-if="i != field.options.length - 1"
                              :key="`${option.key}.line`"
                              :aria-field-name="field.label"
                            ></div>
                          </div>
                        </div>
                        <div v-else class="col-sm-10">
                          <input-multi-checkbox
                            v-model="data[field.key]"
                            :options="field.options"
                            :name="field.key"
                            :readonly="!ediable(field)"
                            :disabled="disable(field)"
                            :ripple="true"
                          ></input-multi-checkbox>
                          <span class="form-text" v-if="field.description">{{ field.description }}</span>
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'radio')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <input-radio
                            v-for="(option, i) in field.options"
                            :key="i"
                            v-model="data[field.key]"
                            :name="field.key"
                            :expected="option.value"
                            :readonly="!ediable(field)"
                            :disabled="disable(field)"
                            :ripple="true"
                            >{{ option.label }}</input-radio
                          >
                          <span class="form-text" v-if="field.description">{{ field.description }}</span>
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'select')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <select
                            v-model="data[field.key]"
                            class="form-control"
                            @change="onChange(field, data[field.key])"
                            :multiple="field.multiple && 'multiple'"
                          >
                            <option value="" v-if="field.multiple !== true && field.key !== 'promotion_type'">
                              {{ field.placeholder || '' }}
                            </option>
                            <option v-for="option in field.options" :key="option.value" :value="option.value">
                              {{ option.label }}
                            </option>
                          </select>
                          <span class="form-text" v-if="field.description">{{ field.description }}</span>
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'editor')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <Editor v-model="data[field.key]"></Editor>
                          <span class="form-text" v-if="field.description">{{ field.description }}</span>
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'date')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <datetime
                            v-model="data[field.key]"
                            class="form-control"
                            type="date"
                            zone="local"
                            :name="field.key"
                            :week-start="0"
                            :placeholder="field.placeholder || ''"
                            :readonly="!ediable(field)"
                            :disabled="disable(field)"
                          />
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'datetime')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <datetime
                            ref="datetime"
                            v-model="data[field.key]"
                            class="form-control"
                            type="datetime"
                            zone="local"
                            :name="field.key"
                            :week-start="0"
                            :readonly="!ediable(field)"
                            :disabled="disable(field)"
                          />
                          <span class="form-text" v-if="field.description">{{ field.description }}</span>
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'resource')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <input
                            v-model="data[field.key]"
                            class="form-control"
                            type="text"
                            :name="field.key"
                            :placeholder="field.placeholder || ''"
                            :readonly="!ediable(field)"
                            :disabled="disable(field)"
                          />
                          <a :href="data[field.key]" target="_blank">OPEN</a>
                          <span class="form-text" v-if="field.description">{{ field.description }}</span>
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'extra-editor')">
                      <div class="form-group row" :key="field.key" :class="{ 'no-display': !couponVisible(field) }">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <ExtraEditor
                            v-model="data[field.key]"
                            :mode="mode"
                            :fields="field.fields"
                            :readonly="!ediable(field)"
                          ></ExtraEditor>
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'resource-editor')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <ResourceEditor
                            v-model="data[field.key]"
                            :resource-key="data['resource_key']"
                            :mode="mode"
                            :fields="field.fields"
                            :readonly="!ediable(field)"
                          ></ResourceEditor>
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'postcode-select')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <PostCodeSelect
                            v-model="data[field.key]"
                            :name="field.key"
                            @complete="field.onComplete(_self, field, data, $event)"
                            :readonly="!ediable(field)"
                            :disabled="disable(field)"
                          ></PostCodeSelect>
                          <span class="form-text"></span>
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'locale-editor')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <LocaleEditor
                            v-model="data[field.key]"
                            :mode="mode"
                            :name="field.key"
                            :type="field.localeType"
                            :locales="field.locales"
                            :readonly="!ediable(field)"
                          ></LocaleEditor>
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'timetable-editor')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <TimeTableEditor
                            v-model="data[field.key]"
                            :name="field.key"
                            :readonly="!ediable(field)"
                          ></TimeTableEditor>
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'custom-select')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <CustomSelect
                            v-model="data[field.key]"
                            :options="field.getOptions(_self, data[field.key])"
                            :name="field.key"
                            :readonly="!ediable(field)"
                            :disabled="disable(field)"
                          ></CustomSelect>
                          <span class="form-text"></span>
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'user-select')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <UserSelect
                            v-model="data[field.key]"
                            :name="field.key"
                            :readonly="!ediable(field)"
                            :disabled="disable(field)"
                          ></UserSelect>
                          <span class="form-text"></span>
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'agent-select')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <AgentSelect
                            v-model="data[field.key]"
                            :name="field.key"
                            :readonly="!ediable(field)"
                            :disabled="disable(field)"
                          ></AgentSelect>
                          <span class="form-text"></span>
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'host-select')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <HostSelect
                            v-model="data[field.key]"
                            :name="field.key"
                            :readonly="!ediable(field)"
                            :disabled="disable(field)"
                          ></HostSelect>
                          <span class="form-text"></span>
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'promotion-select')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <PromotionSelect
                            v-model="data[field.key]"
                            :name="field.key"
                            :group="field.group"
                            :readonly="!ediable(field)"
                            :disabled="disable(field)"
                          ></PromotionSelect>
                          <span class="form-text"></span>
                        </div>
                      </div>
                    </template>

                    <!-- <template v-if="visible(tab, field, 'coupon-datetime')">
											<div class="form-group row" :key="`${field.key}.datetime`">
												<label class="col-sm-2 col-form-label">{{ field.label }}</label>

												<div class="col-sm-10">
													<datetime v-model="data[field.key]" class="form-control" type="datetime" zone="local" :name="field.key" :week-start="0" :readonly="!ediable(field)" :disabled="disable(field)" />
													<span class="form-text" v-if="field.description">{{ field.description }}</span>
												</div>
											</div>
											<div class="form-group row m-t-sm" :key="`${field.key}.check`">
												<label class="col-sm-2 col-form-label"> </label>

												<div class="col-sm-10">
													<input-checkbox :name="field.key + '_check'" :expected="true" :value="field.getCheckValue(_self, data)" @input="field.onCheck(_self, $event, data)" :ripple="true">유효기간 없음</input-checkbox>
												</div>
											</div>
										</template> -->

                    <template v-if="visible(tab, field, 'promotion-datetime')">
                      <div class="form-group row" :key="`${field.key}.datetime`">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>

                        <div class="col-sm-10">
                          <datetime
                            v-model="data[field.key]"
                            class="form-control"
                            type="datetime"
                            zone="local"
                            :name="field.key"
                            :placeholder="data[field.key]"
                            :week-start="0"
                            :readonly="!ediable(field)"
                            :disabled="disable(field)"
                          />
                          <span class="form-text" v-if="field.description">{{ field.description }}</span>
                        </div>
                      </div>
                      <div class="form-group row m-t-sm" :key="`${field.key}.check`">
                        <label class="col-sm-2 col-form-label"> </label>

                        <div class="col-sm-10">
                          <input-checkbox
                            :name="field.key + '_check'"
                            :expected="true"
                            :value="field.getCheckValue(_self, data)"
                            @input="field.onCheck(_self, $event, data)"
                            :ripple="true"
                            >유효기간 없음</input-checkbox
                          >
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'bank-select')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <BankSelect
                            v-model="data[field.key]"
                            :name="field.key"
                            @selected-data="field.onSelect(_self, $event, data)"
                            :readonly="!ediable(field)"
                            :disabled="disable(field)"
                          ></BankSelect>
                          <span class="form-text"></span>
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'store-select')">
                      <div class="form-group row" :key="field.key">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <StoreSelect
                            v-model="data[field.key]"
                            :name="field.key"
                            @selected-data="field.onSelect(_self, $event, data)"
                            :readonly="!ediable(field)"
                            :disabled="disable(field)"
                          ></StoreSelect>
                          <span class="form-text"></span>
                        </div>
                      </div>
                    </template>

                    <template v-if="visible(tab, field, 'addable-editor')">
                      <div class="form-group row" :key="field.key" :class="{ 'no-display': !couponVisible(field) }">
                        <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                        <div class="col-sm-10">
                          <AddableEditor
                            v-model="data[field.key]"
                            :mode="mode"
                            :fields="field.fields"
                            :readonly="!ediable(field)"
                            :prefix="field.prefix"
                            :minLength="field.minLength"
                            :maxLength="field.maxLength"
                          ></AddableEditor>
                        </div>
                      </div>
                    </template>

                    <div
                      class="hr-line-dashed"
                      v-if="visible(tab, field, 'line')"
                      :key="`${field.key}.line`"
                      :aria-field-name="field.label"
                      :class="{ 'no-display': !couponVisible(field) }"
                    ></div>
                  </template>
                </fieldset>

                <div class="form-group row">
                  <div class="actions">
                    <div class="left">
                      <button class="btn btn-white btn-sm" @click="onClickBack">Back</button>
                    </div>

                    <div class="right">
                      <template v-for="action in actions">
                        <button
                          class="btn btn-xs"
                          :key="action.action"
                          v-if="action.use(mode, data)"
                          :class="action.class"
                          :disabled="loading"
                          @click="action.onAction(data, _self)"
                        >
                          {{ action.label }}
                        </button>
                      </template>
                      <template v-if="model.name === 'User' && data.auth_type === 'user'">
                        <b-button class="btn btn-xs" v-b-modal.modal-prevent-closing>비밀번호 재설정</b-button>
                        <b-modal
                          id="modal-prevent-closing"
                          ref="modal"
                          title="사용자 비밀번호 재설정"
                          @show="resetModal"
                          @hidden="resetModal"
                          @ok="handleOk"
                        >
                          <form ref="momo" @submit.stop.prevent="handleSubmit">
                            <b-form-group
                              :state="passwordState"
                              label="새로운 비밀번호"
                              label-for="password-input"
                              invalid-feedback="비밀번호는 공백일 수 없습니다."
                            >
                              <b-form-input
                                id="password-input"
                                v-model="password"
                                :state="passwordState"
                                required
                              ></b-form-input>
                            </b-form-group>
                          </form>
                        </b-modal>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from '@/components/utils/Editor';
import ExtraEditor from '@/components/utils/ExtraEditor';
import ResourceEditor from '@/components/utils/ResourceEditor';
import TimeTableEditor from '@/components/utils/TimeTableEditor';
import LocaleEditor from '@/components/utils/LocaleEditor';
import CustomSelect from '@/components/utils/CustomSelect';
import PostCodeSelect from '@/components/utils/PostCodeSelect';
import HostSelect from '@/components/utils/HostSelect';
import PromotionSelect from '@/components/utils/PromotionSelect';
import UserSelect from '@/components/utils/UserSelect';
import AgentSelect from '@/components/utils/AgentSelect';
import BankSelect from '@/components/utils/BankSelect';
import StoreSelect from '@/components/utils/StoreSelect';
import AddableEditor from '@/components/utils/AddableEditor.vue';

export default {
  name: 'Form',
  data() {
    return {
      mode: 'unset',
      model: {},
      data: {},
      activedTab: 'default',
      tabs: [],
      fields: [],
      actions: [],
      loading: false,
      timestamp: +new Date(),
      password: '',
      passwordState: null,
      couponVisibility: true,
    };
  },
  mounted() {
    this.$data.mode = this.$route.meta.mode; //meta: { mode: "create", title: model.views.create.title, model: model, noCache: true }
    this.$data.model = this.$route.meta.model; // model ===  name: modelData.name, fields: fields, actions: actions, groups: groups, types: {
    this.$data.tabs = this.model.tabs || [{ id: 'default' }];
    this.$data.activedTab = this.$data.tabs[0].id;
    this.$data.fields = this.model.fields.filter((field) => field);
    this.$data.actions = this._.map(this.model.actions.form, (actionData) => {
      //{ list: ['create'], form: ['submit'] }
      let action =
        typeof actionData === 'string' //actionData === 'submit'
          ? this.getAction(actionData) //use: (mode) => .., action: 'create', class: 'btn-success', label: 'Create', onAction: this.onClickSubmit, .. --> onClickSubmit === this.onSubmit()
          : {
              ...this.getAction(actionData.action),
              ...actionData,
            };

      return {
        ...action,
        onAction: async (row, component) => {
          component.$data.loading = true;

          try {
            if (typeof action.willAction === 'function') {
              await action.willAction(row, component);
            }

            await action.onAction(row, component);

            if (typeof action.didAction === 'function') {
              await action.didAction(row, component);
            }
          } catch (e) {
            component.$toast.error(e.message);
          }

          component.$data.loading = false;
        },
      };
    });

    if (this.mode === 'create') {
      this.$data.data['promotion_config'] = {};
      this.$data.data['resource_key'] = this.$utils.uuid();

      this.setupDefaultValues();
    } else {
      this.loadData();
    }
  },
  watch: {
    data: {
      handler() {
        this.$data.timestamp = +new Date();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onChange(field, data) {
      if (field.key == 'promotion_type' && data == 'discount-code') {
        this.couponVisibility = false;
      } else {
        this.couponVisibility = true;
      }
    },

    checkFormValidity() {
      const valid = this.$refs.momo[0].checkValidity();
      this.passwordState = valid;
      return valid;
    },

    resetModal() {
      this.password = '';
      this.passwordState = null;
    },

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },

    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // 서버로 비밀번호 리셋 요청 보내기
      this.$route.params['password'] = this.password;
      this.$store
        .dispatch(this.model.types.resetPw.action, this.$route.params)
        .then((data) => {
          this.$toast.success('수정되었습니다.');
        })
        .catch((e) => {
          console.error(e);
          this.$toast.error(e.message);
        });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing');
      });
    },

    setupDefaultValues() {
      this._.map(this.fields, (field) => {
        if (typeof field.defaultValue !== 'undefined') {
          this.$data.data[field.key] = field.defaultValue;
        }
      });
    },
    loadData() {
      this.$data.loading = true;
      this.$store
        .dispatch(this.model.types.get.action, this.$route.params)
        .then((data) => {
          this.$data.data = this.$utils.parseJSON(data);
          this.$data.timestamp = +new Date();
        })
        .catch((e) => {
          console.error(e);
          this.$toast.error(e.message);
        })
        .finally(() => {
          this.$data.loading = false;
        });
    },

    ediable(field) {
      if (field.type === 'resource') {
        return false;
      }

      if (this.mode === 'view') {
        return false;
      }

      return (this.mode !== 'create' && field.editable === false) || field.readonly === true ? false : true;
    },

    disable(field) {
      if (field.primaryKey) {
        return true;
      }

      if (field.editable == false) {
        return this.mode !== 'create';
      }

      return false;
    },

    visible(tab, field, type) {
      if (field.tab) {
        if (this._.isArray(field.tab)) {
          if (!field.tab.includes(tab.id)) {
            return false;
          }
        } else if (this._.isString(field.tab)) {
          if (field.tab !== '*' && field.tab !== tab.id) {
            return false;
          }
        }
      } else if (this.activedTab !== this.tabs[0].id) {
        return false;
      }

      if (field.type === type || type === 'line') {
        if (field.key === 'store_name' && type === 'line') {
          // console.log( field, type )
        }

        if (field.hidden === true) {
          if (field.key === 'store_name' && type === 'line') {
            console.log('#1');
          }
          return false;
        }

        if (field.primaryKey) {
          if (this.mode === 'create') {
            if (field.key === 'store_name' && type === 'line') {
              console.log('#2');
            }
            return false;
          }
        }

        if (field.condition && !field.condition(this.data)) {
          return false;
        }

        if (field.readonly === true) {
          if (field.key === 'store_name' && type === 'line') {
            console.log('#3');
          }
          return this.mode !== 'create';
        }

        return true;
      }

      if (field.key === 'store_name' && type === 'line') {
        console.log('#4');
      }

      return false;
    },

    couponVisible(field) {
      if (field.key === 'extras' && this.data.promotion_type && this.data.promotion_type == 'discount-code') {
        return false;
      } else {
        return true;
      }
    },

    fieldValue(field, row) {
      if (field.dataType === 'date' || field.dataType === 'datetime') {
        let dateValue = field.alias ? this.$utils.value(row, field.alias) : row[field.key];
        let date = new Date(dateValue);

        if (!date || date == 'Invalid Date') {
          return '';
        }

        return this.$moment(date).format(field.format);
      }

      if (this._.isString(field.alias)) {
        return this.$utils.value(row, field.alias);
      } else if (this._.isFunction(field.alias)) {
        return field.alias(field, row);
      }

      let value = row[field.key];
      if (typeof field.locale === 'function') {
        return field.locale(value);
      }

      return value;
    },

    getAction(action) {
      if (action === 'submit') {
        if (this.mode === 'create') {
          return {
            use: (mode) => {
              return true;
            },
            action: 'create',
            class: 'btn-success',
            label: 'Create',
            onAction: this.onClickSubmit,
          };
        }

        return {
          use: (mode) => {
            return true;
          },
          action: 'edit',
          class: 'btn-success',
          label: 'Save Changes',
          onAction: this.onClickSubmit,
        };
      }

      return {
        use: (mode) => {
          return false;
        },
        action: action,
        class: '',
        label: action,
        onAction: () => {
          console.log('unknown action', action);
        },
      };
    },

    onClickTab(e, tab) {
      this.$data.activedTab = tab.id;
    },

    onClickBack(e) {
      this.$router.back();
    },

    onClickSubmit(data, component) {
      return this.onSubmit();
    },

    async onSubmit() {
      let result = this.$validator.validate({ fields: this.fields, data: this.data });

      if (result) {
        this.$toast.error(result);
        return;
      }

      let data = await this.$store.dispatch(this.model.types[this.mode].action, this.$data.data);

      if (this.mode === 'create') {
        this.$toast.success('생성되었습니다.');
      } else {
        this.$toast.success('수정되었습니다.');
      }

      this.$data.data = data;
      this.$data.timestamp = +new Date();

      if (this.$data.actions.some((action) => action.action === 'toList')) {
        this.$router.replace({
          name: this.model.views.list.name,
        });
        return;
      }

      if (this.$data.actions.some((action) => action.action === 'toManage')) {
        this.$router.replace({
          name: this.model.views.manage.name,
          params: this.model.views.manage.params(data),
        });
        return;
      }

      if (this.$route.name !== this.model.views.edit.name) {
        this.$router.replace({
          name: this.model.views.edit.name,
          params: this.model.views.edit.params(data),
        });
      }

      return data;
    },
  },
  components: {
    Editor,
    ExtraEditor,
    ResourceEditor,
    TimeTableEditor,
    LocaleEditor,
    PostCodeSelect,
    CustomSelect,
    HostSelect,
    PromotionSelect,
    UserSelect,
    AgentSelect,
    BankSelect,
    StoreSelect,
    AddableEditor,
  },
};
</script>
