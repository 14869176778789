<template>
  <div class="addable-editor">
    <div class="debug" v-if="0">
      <pre v-if="debug">{{ data }}</pre>
      <button variant="link" @click="onToggleDebug">DEBUG</button>
    </div>

    <ul class="list-group">
      <li v-for="(itemValue, name) in data" class="list-group-item p-5">
        <div>
          <div v-for="(field, index) in fields" :aria-timestamp="timestamp">
            <!-- <template v-if="field.name && data[`${getKey(number)}`] && data[`${getKey(number)}`][field.name]"> -->
            <template v-if="visible(field, 'text')">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    :type="field.secure ? 'password' : 'text'"
                    :name="field.name"
                    :placeholder="field.placeholder || ''"
                    :readonly="!editable(field)"
                    :disabled="disable(field)"
                    :value="itemValue[field.name]"
                    @input="itemValue[field.name] = $event.target.value"
                  />
                  <span class="form-text" v-if="field.description">{{ field.description }}</span>
                </div>
              </div>
            </template>

            <template v-if="visible(field, 'textarea')">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                <div class="col-sm-10">
                  <textarea
                    class="form-control"
                    :rows="field.rows || 5"
                    :name="field.name"
                    :placeholder="field.placeholder || ''"
                    :readonly="!editable(field)"
                    :disabled="disable(field)"
                    :value="itemValue[field.name]"
                    @input="itemValue[field.name] = $event.target.value"
                  />
                  <span class="form-text" v-if="field.description">{{ field.description }}</span>
                </div>
              </div>
            </template>

            <template v-if="visible(field, 'json')">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                <div class="col-sm-10">
                  <span class="json-text">{{ itemValue[field.name] }}</span>
                  <span class="form-text" v-if="field.description">{{ field.description }}</span>
                </div>
              </div>
            </template>

            <template v-if="visible(field, 'checkbox')">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                <div class="col-sm-10">
                  <input-checkbox
                    v-model="itemValue[field.name]"
                    :name="field.name"
                    :expected="field.expected"
                    :readonly="!editable(field)"
                    :disabled="disable(field)"
                    :ripple="true"
                    >{{ field.label }}</input-checkbox
                  >
                  <span class="form-text" v-if="field.description">{{ field.description }}</span>
                </div>
              </div>
            </template>

            <template v-if="visible(field, 'radio')">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                <div class="col-sm-10">
                  <input-radio
                    v-for="(option, i) in field.options"
                    v-model="itemValue[field.name]"
                    :key="i"
                    :expected="option.value"
                    :readonly="!editable(field)"
                    :disabled="disable(field)"
                    :ripple="true"
                    >{{ option.label }}</input-radio
                  >
                  <span class="form-text" v-if="field.description">{{ field.description }}</span>
                </div>
              </div>
            </template>

            <template v-if="visible(field, 'select')">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                <div class="col-sm-10">
                  <select
                    class="form-control"
                    v-model="itemValue[field.name]"
                    :name="field.name"
                    :multiple="field.multiple && 'multiple'"
                    :readonly="!editable(field)"
                    :disabled="disable(field)"
                    @input="itemValue[field.name] = $event.target.value"
                  >
                    <option value="" v-if="field.multiple !== true">{{ field.placeholder || '' }}</option>
                    <option v-for="option in field.options" :value="option.value">{{ option.label }}</option>
                  </select>
                  <span class="form-text" v-if="field.description">{{ field.description }}</span>
                </div>
              </div>
            </template>

            <template v-if="visible(field, 'date')">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                <div class="col-sm-10">
                  <datetime
                    class="form-control"
                    type="date"
                    zone="local"
                    v-model="itemValue[field.name]"
                    :name="field.name"
                    :week-start="0"
                    :placeholder="field.placeholder || ''"
                    :readonly="!editable(field)"
                    :disabled="disable(field)"
                  />
                </div>
              </div>
            </template>

            <template v-if="visible(field, 'datetime')">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                <div class="col-sm-10">
                  <datetime
                    class="form-control"
                    type="datetime"
                    zone="local"
                    v-model="itemValue[field.name]"
                    :name="field.name"
                    :week-start="0"
                    :readonly="!editable(field)"
                    :disabled="disable(field)"
                  />
                  <span class="form-text" v-if="field.description">{{ field.description }}</span>
                </div>
              </div>
            </template>

            <template v-if="visible(field, 'editor')">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                <div class="col-sm-10">
                  <Editor v-model="itemValue[field.name]"></Editor>
                  <span class="form-text" v-if="field.description">{{ field.description }}</span>
                </div>
              </div>
            </template>

            <template v-if="visible(field, 'locale-editor')">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                <div class="col-sm-10">
                  <LocaleEditor
                    v-model="itemValue[field.name]"
                    :mode="mode"
                    :name="field.name"
                    :type="field.localeType"
                    :locales="field.locales"
                    :readonly="!editable(field)"
                  ></LocaleEditor>
                </div>
              </div>
            </template>

            <template v-if="visible(field, 'promotion-select')">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">{{ field.label }}</label>
                <div class="col-sm-10">
                  <PromotionSelect
                    v-model="itemValue[field.name]"
                    :name="field.name"
                    :group="field.group"
                    :readonly="!editable(field)"
                    :disabled="disable(field)"
                  ></PromotionSelect>
                  <span class="form-text"></span>
                </div>
              </div>
            </template>
            <div class="hr-line-dashed hr-line-dashed--small" v-if="index < fields.length - 1"></div>
            <!-- </template> -->
          </div>
        </div>

        <div class="form-group mt-4 mb-4">
          <div class="mr-0 text-right">
            <button class="btn btn-warning btn-ml" :disabled="minLength >= length" @click="removeItem(name)">
              삭제
            </button>
          </div>
        </div>
      </li>
    </ul>

    <div class="form-group mt-4 mb-4">
      <div class="mr-0 text-right">
        <button class="btn btn-info btn-ml" :disabled="maxLength <= length" @click="addItem">추가</button>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from '@/components/utils/Editor';
import LocaleEditor from '@/components/utils/LocaleEditor';
import PromotionSelect from '@/components/utils/PromotionSelect';
import utils from '@/utils';

export default {
  mounted() {
    let data = utils.parseJSON(this.value);

    this.$data.data = data;
  },
  data() {
    return {
      debug: true,
      data: {},
      timestamp: +new Date(),
    };
  },
  props: {
    mode: {
      type: String,
      default: '',
    },
    fields: {
      type: Array,
      required: true,
      default: () => [],
    },
    value: {
      type: [String, Object],
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
    },
    minLength: {
      type: Number,
      default: 0,
    },
    maxLength: {
      type: Number,
      default: 10,
    },
  },
  watch: {
    value(value, oldValue) {
      // console.log( 'value', value, oldValue )

      this.$data.data = utils.parseJSON(value);
      this.$data.timestamp = +new Date();
    },
    data: {
      handler(value, oldValue) {
        this.$emit('input', value);
        this.$data.timestamp = +new Date();
      },
      deep: true,
    },
  },

  computed: {
    length() {
      return Object.keys(this.$data.data).length;
    },
  },

  methods: {
    setData(data) {
      this.$emit('input', data);

      this.$data.timestamp = +new Date();
    },

    editable(field) {
      return (this.mode !== 'create' && field.editable === false) || field.readonly === true ? false : true;
    },

    disable(field) {
      if (field.primaryKey) {
        return true;
      }

      if (field.editable == false) {
        return this.mode !== 'create';
      }

      if (typeof field.disable === 'function') {
        return field.disable(this.value);
      }

      if (field.disabledOnCreate == true && this.mode == 'create') {
        return true;
      }

      return false;
    },

    visible(field, type) {
      if (field.type === type) {
        if (field.hidden === true) {
          return false;
        }

        if (field.primaryKey) {
          if (this.mode === 'create') {
            return false;
          }
        }

        if (field.readonly === true) {
          return this.mode !== 'create';
        }

        if (field.condition && field.condition(this.data)) {
          return;
        }

        return true;
      }

      return false;
    },

    onToggleDebug() {
      this.$data.debug = !this.$data.debug;
    },

    onChange(target, e) {
      let name = e.target.name;
      let value = e.target.value;
      let data = this.data;
      data[name] = value;

      this.setData(data);
    },

    addItem() {
      const prop = {};
      this.fields.forEach((o) => (prop[o.name] = ''));

      const name = this.prefix ? `${this.prefix}${this.length + 1}` : this.length + 1;
      this.$set(this.data, name, prop);
    },

    removeItem(name) {
      this.$delete(this.data, name);
    },
  },
  components: {
    Editor,
    LocaleEditor,
    PromotionSelect,
  },
};
</script>
