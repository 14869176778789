<template>
  <search-select
    v-model="selectedItem" class="form-control"
    :placeholder="placeholder"
    :name="name"
    :is-disabled="disabled"
    :options="options"
    @searchchange="onChange" >
  </search-select>
</template>

<script>

export default {
  mounted() {
    this.onLoad( this.value )
  },
  data() {
    return {
      selectedItem: { value: '', text: '' },
      options: []
    }
  },
  props: {
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      required: true,
      default: ''
    }
  },
  watch: {
    value( value, oldValue ) {
      this.onLoad( value )
    },
    selectedItem( item, oldItem ) {
      this.setValue( item.value )
    }
  },
  methods: {
    setValue( value ) {
      this.$emit( "input", value)
    },
    onLoad( value ) {
      if (!value) { return }

      this.$emit( "load", {} )

      this.$store.dispatch('store/get', { uid: value })
        .then((data) => {
          let { uid, store_name_localed } = data

          let selectedItem = {
            text: store_name_localed + ` (${uid})`,
            value: uid
          }

          this.$data.options = [selectedItem]
          this.$data.selectedItem = selectedItem
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
    },
    onChange(keyword) {
      if (!keyword) { return }

      this.$emit( "change", { keyword } )

      this.$store.dispatch('store/list', { page: 1, size: 5, filter: isNaN(keyword) ? 'all' : 'id', keyword: keyword })
        .then((data) => {
          let { items } = data
          let options = []
          this._.forEach(items, (item) => {
            let { uid, store_name_localed } = item
            options.push({
              text: store_name_localed + ` (${uid})`,
              value: uid
            })
          })
          this.$data.options = options
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
    }
  }
}
</script>
