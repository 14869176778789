<template>
  <div class="postcode-select">
    <input ref="input" class="form-control" type="text" :name="name" :value="value" @click="onClickInput" />
    
    <div class="geocode-provider" v-if="geocodeResult.loaded">
      <div class="info-message" v-if="!geocode_provider">적용할 좌표를 선택해주세요</div>

      <div class="result">
        <input-radio v-model="geocode_provider" name="geocode_provider" expected="naver" :ripple="true">
          <div class="geocode">
            <div class="provider">Naver API</div>
            <ul>
              <li><dt>위도</dt> <dd>{{geocodeResult.naver.geo_latitude}}</dd></li>
              <li><dt>경도</dt> <dd>{{geocodeResult.naver.geo_longitude}}</dd></li>
            </ul>
          </div>
        </input-radio>
        <input-radio v-model="geocode_provider" name="geocode_provider" expected="google" :ripple="true">
          <div class="geocode">
            <div class="provider">Google API</div>
            <ul>
              <li><dt>위도</dt> <dd>{{geocodeResult.google.geo_latitude}}</dd></li>
              <li><dt>경도</dt> <dd>{{geocodeResult.google.geo_longitude}}</dd></li>
            </ul>
          </div>
        </input-radio>
      </div>
    </div>

    <portal to="app-content-after" v-if="isOpen">
      <div class="postcode-select-layer" @click="onClickLayer">
        <daum-postcode :q="query" @complete="onComplete" ></daum-postcode>
      </div>
    </portal>
  </div>
</template>

<script>

export default {
  mounted() {

  },
  data() {
    return {
      callback: null,
      geocode_provider: '',
      geocodeResult: {
        loaded: false,
        naver: {
          geo_latitude: 0,
          geo_longitude: 0
        },
        google: {
          geo_latitude: 0,
          geo_longitude: 0
        }
      },
      isOpen: false,
    }
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    query: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  },
  watch: {
    geocode_provider(value, oldValue) {
      if (this.callback && value) {
        let geodata = this.geocodeResult[value]
        if (typeof this.callback === 'function') {
          this.callback(geodata)
        }
      }
    }
  },
  methods: {
    onClickInput(e) {
      this.$data.isOpen = true
    },
    onClickLayer(e) {
      this.$data.isOpen = false
    },
    
    onComplete( data ) {
      let { zonecode } = data

      this.$data.callback = null
      this.$data.geocode_provider = ''

      this.$emit("input", zonecode)
      this.$emit("complete", {
        ...data,
        geoCodeProvider: this.geocode_provider,
        callback: (result, callback) => {
          this.$data.geocodeResult = result
          this.$data.callback = callback

          if(this.$data.geocodeResult.naver.geo_latitude > 0 && this.$data.geocodeResult.naver.geo_longitude){
            this.$data.geocode_provider = 'naver'
          }
        }
      })
      this.$data.isOpen = false
    }
  }
}
</script>

<style>

</style>
