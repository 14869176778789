<template>
<div class="timetable-editor">
  <ul class="days_table">
    <li>
      <ul>
        <li>
          <input-checkbox v-model="table.SA_MON.open" @change="onChange" >{{"MON"}}</input-checkbox>
        </li>
        <li>
          <b-form-select v-model="table.SA_MON.start" label="'오픈 시각'" :options="timesStart" :disabled="!table.SA_MON.open" @change="onChange" />
        </li>
        <li>
          <b-form-select v-model="table.SA_MON.end" label="'마감 시각'" :options="timesEnd" :disabled="!table.SA_MON.open"  @change="onChange" />
        </li>
      </ul>
    </li>
    <li>
      <ul>
        <li>
          <input-checkbox v-model="table.SA_TUE.open" @change="onChange" >{{"TUE"}}</input-checkbox>
        </li>
        <li>
          <b-form-select v-model="table.SA_TUE.start" label="'오픈 시각'" :options="timesStart" :disabled="!table.SA_TUE.open" @change="onChange" />
        </li>
        <li>
          <b-form-select v-model="table.SA_TUE.end" label="'마감 시각'" :options="timesEnd" :disabled="!table.SA_TUE.open" @change="onChange" />
        </li>
      </ul>
    </li>
    <li>
      <ul>
        <li>
          <input-checkbox v-model="table.SA_WED.open" @change="onChange" >{{"WED"}}</input-checkbox>
        </li>
        <li>
          <b-form-select v-model="table.SA_WED.start" label="'오픈 시각'" :options="timesStart" :disabled="!table.SA_WED.open" @change="onChange" />
        </li>
        <li>
          <b-form-select v-model="table.SA_WED.end" label="'마감 시각'" :options="timesEnd" :disabled="!table.SA_WED.open" @change="onChange" />
        </li>
      </ul>
    </li>
    <li>
      <ul>
        <li>
          <input-checkbox v-model="table.SA_THU.open" @change="onChange" >{{"THU"}}</input-checkbox>
        </li>
        <li>
          <b-form-select v-model="table.SA_THU.start" label="'오픈 시각'" :options="timesStart" :disabled="!table.SA_THU.open" @change="onChange" />
        </li>
        <li>
          <b-form-select v-model="table.SA_THU.end" label="'마감 시각'" :options="timesEnd" :disabled="!table.SA_THU.open" @change="onChange" />
        </li>
      </ul>
    </li>
    <li>
      <ul>
        <li>
          <input-checkbox v-model="table.SA_FRI.open" @change="onChange" >{{"FRI"}}</input-checkbox>
        </li>
        <li>
          <b-form-select v-model="table.SA_FRI.start" label="'오픈 시각'" :options="timesStart" :disabled="!table.SA_FRI.open" @change="onChange" />
        </li>
        <li>
          <b-form-select v-model="table.SA_FRI.end" label="'마감 시각'" :options="timesEnd" :disabled="!table.SA_FRI.open" @change="onChange" />
        </li>
      </ul>
    </li>
    <li>
      <ul>
        <li>
          <input-checkbox v-model="table.SA_SAT.open" @change="onChange" >{{"SAT"}}</input-checkbox>
        </li>
        <li>
          <b-form-select v-model="table.SA_SAT.start" label="'오픈 시각'" :options="timesStart" :disabled="!table.SA_SAT.open" @change="onChange" />
        </li>
        <li>
          <b-form-select v-model="table.SA_SAT.end" label="'마감 시각'" :options="timesEnd" :disabled="!table.SA_SAT.open" @change="onChange" />
        </li>
      </ul>
    </li>
    <li>
      <ul>
        <li>
          <input-checkbox v-model="table.SA_SUN.open" @change="onChange" >{{"SUN"}}</input-checkbox>
        </li>
        <li>
          <b-form-select v-model="table.SA_SUN.start" label="'오픈 시각'" :options="timesStart" :disabled="!table.SA_SUN.open" @change="onChange" />
        </li>
        <li>
          <b-form-select v-model="table.SA_SUN.end" label="'마감 시각'" :options="timesEnd" :disabled="!table.SA_SUN.open" @change="onChange" />
        </li>
      </ul>
    </li>
    <li>
      <ul>
        <li>
          <input-checkbox v-model="table.SA_HOL.open" @change="onChange" >{{"HOL"}}</input-checkbox>
        </li>
        <li>
          <b-form-select v-model="table.SA_HOL.start" label="'오픈 시각'" :options="timesStart" :disabled="!table.SA_HOL.open" @change="onChange" />
        </li>
        <li>
          <b-form-select v-model="table.SA_HOL.end" label="'마감 시각'" :options="timesEnd" :disabled="!table.SA_HOL.open" @change="onChange" />
        </li>
      </ul>
    </li>
  </ul>
</div>
</template>


<script>

export default {
  data() {
    var table = {
      "SA_MON" : { open: true, start: "00:00", end: "01:00" },
      "SA_TUE" : { open: true, start: "00:00", end: "01:00" },
      "SA_WED" : { open: true, start: "00:00", end: "01:00" },
      "SA_THU" : { open: true, start: "00:00", end: "01:00" },
      "SA_FRI" : { open: true, start: "00:00", end: "01:00" },
      "SA_SAT" : { open: true, start: "00:00", end: "01:00" },
      "SA_SUN" : { open: true, start: "00:00", end: "01:00" },
      "SA_HOL" : { open: true, start: "00:00", end: "01:00" },
    };
    var days = Object.keys( table );
    var timesStart = [
      { text: "00:00", value: "00:00" },{ text: "01:00", value: "01:00" },{ text: "02:00", value: "02:00" },{ text: "03:00", value: "03:00" },
      { text: "04:00", value: "04:00" },{ text: "05:00", value: "05:00" },{ text: "06:00", value: "06:00" },{ text: "07:00", value: "07:00" },
      { text: "08:00", value: "08:00" },{ text: "09:00", value: "09:00" },{ text: "10:00", value: "10:00" },{ text: "11:00", value: "11:00" },
      { text: "12:00", value: "12:00" },{ text: "13:00", value: "13:00" },{ text: "14:00", value: "14:00" },{ text: "15:00", value: "15:00" },
      { text: "16:00", value: "16:00" },{ text: "17:00", value: "17:00" },{ text: "18:00", value: "18:00" },{ text: "19:00", value: "19:00" },
      { text: "20:00", value: "20:00" },{ text: "21:00", value: "21:00" },{ text: "22:00", value: "22:00" },{ text: "23:00", value: "23:00" },
    ];
    var timesEnd = [
      { text: "01:00", value: "01:00" },{ text: "02:00", value: "02:00" },{ text: "03:00", value: "03:00" },{ text: "04:00", value: "04:00" },
      { text: "05:00", value: "05:00" },{ text: "06:00", value: "06:00" },{ text: "07:00", value: "07:00" },{ text: "08:00", value: "08:00" },
      { text: "09:00", value: "09:00" },{ text: "10:00", value: "10:00" },{ text: "11:00", value: "11:00" },{ text: "12:00", value: "12:00" },
      { text: "13:00", value: "13:00" },{ text: "14:00", value: "14:00" },{ text: "15:00", value: "15:00" },{ text: "16:00", value: "16:00" },
      { text: "17:00", value: "17:00" },{ text: "18:00", value: "18:00" },{ text: "19:00", value: "19:00" },{ text: "20:00", value: "20:00" },
      { text: "21:00", value: "21:00" },{ text: "22:00", value: "22:00" },{ text: "23:00", value: "23:00" },{ text: "24:00", value: "24:00" },
      { text: "25:00", value: "25:00" },{ text: "26:00", value: "26:00" },{ text: "27:00", value: "27:00" },{ text: "28:00", value: "28:00" },
      { text: "29:00", value: "29:00" },{ text: "30:00", value: "30:00" },{ text: "31:00", value: "31:00" },{ text: "32:00", value: "32:00" },
      { text: "33:00", value: "33:00" },{ text: "34:00", value: "34:00" },{ text: "35:00", value: "35:00" },{ text: "36:00", value: "36:00" }
    ];
    return {
      checkExtra: [],
      check24Hour: {},
      debug: false,
      table: table,
      days: days,
      timesStart,
      timesEnd,
      data: {}
    }
  },
  mounted() {
    this.onLoad()
  },
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: [String, Object],
      required: true,
      default: ''
    }
  },
  watch: {
    value( value ) {
      try { this.$data.data = typeof value === 'string' ? JSON.parse(value) : value } catch(e) { }

      if ( this.data ) {
        this.updateTable()
      }
    }
  },
  computed: {
    currentData: {
      get() {
        var data = {}
        this.days.forEach( key => {
          let time = this.table[key]
          if ( time.open === true ){
            data[key] = [time.start, time.end]
          }
        })
        return data
      }
    }
  },
  methods: {

    updateTable() {
      this.days.forEach( o => {
        if ( this.data[ o ] ){
          this.table[o].open = true;
          this.table[o].start = this.data[o][0];
          if( parseInt( this.table[o].end ) > 36 ){
            this.table[o].end = ( parseInt( this.table[o].end ) - 36 < 10 ? "0" : "" ) + ( parseInt( this.table[o].end ) - 36 ) + ":00";
          }else{
            this.table[o].end = this.data[o][1];
          }
        }
        else {
          this.table[o].open = false;
          this.table[o].start = "00:00";
          this.table[o].end = "01:00";
        }
      })
    },

    setValue( data ) {
      if (this.value === 'string') {
        this.$emit( "input", JSON.stringify(data))
      }
      else {
        this.$emit( "input", data)
      }
    },

    onLoad() {
      if (!this.value) {
        this.setValue({
          "SA_MON" : [ "00:00", "01:00" ],
          "SA_TUE" : [ "00:00", "01:00" ],
          "SA_WED" : [ "00:00", "01:00" ],
          "SA_THU" : [ "00:00", "01:00" ],
          "SA_FRI" : [ "00:00", "01:00" ],
          "SA_SAT" : [ "00:00", "01:00" ],
          "SA_SUN" : [ "00:00", "01:00" ],
          "SA_HOL" : [ "00:00", "01:00" ],
        })
      }
      else {
        try { this.$data.data = typeof this.value === 'string' ? JSON.parse(this.value) : this.value } catch(e) { }
        this.updateTable()
      }
    },

    onChange(e) {

      this.setValue( this.currentData )
    }
  }
}
</script>

<style>
.days_table ul{
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 1rem;
}
.days_table ul li{
  float: left;
  width: 30%;
}
.days_table{
  padding-left: 0;
  list-style-type: none;
}
</style>
